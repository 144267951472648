import { nextTick } from 'vue';
import { useStore } from 'vuex';
import { isClient, usePreferredReducedMotion, useElementBounding } from '@vueuse/core';

export const useAnchorTag = () => {
  const store = useStore();
  const hasStickyHeader = store?.state?.theme?.header?.options?.sticky_header || false;
  const prefersReducedMotion = usePreferredReducedMotion();

  /**
   * Retrieves the height of the header using the 'view-header' ID set in default.vue.
   * If the element does not exist, it returns 0 since the returned object is initialized with a height of 0.
   *
   * @returns {number} The height of the header element in pixels, or 0 if the element is not found.
   */
  const getStickyHeaderHeight = () => {
    const headerWrapper = document.querySelector('#view-header');
    const { height } = useElementBounding(headerWrapper, {
      updateTiming: 'next-frame',
    });
    return height.value;
  };

  /**
   * Validates if the current route is an anchor route.
   * It checks if the paths are the same and the hashes are different.
   * If the hashes are the same, it checks if the hash is an anchor.
   * @returns {boolean} Whether the current route is an anchor route.
   */
  const validateAnchorRoute = (to, from) => {
    const isSamePath = to.path === from.path;
    const isDifferentHash = to.hash !== from.hash;
    const isSameHashWithAnchor = to.hash && to.hash === from.hash;
    return isSamePath && (isDifferentHash || isSameHashWithAnchor);
  };

  /**
   * Scrolls to the element specified by the current route's hash.
   * This is wrapped in a try-catch block to prevent errors with valid hashes that aren't necessarily valid query selectors like /#?foo=bar (https://drafts.csswg.org/selectors-4/#typedef-selector-list).
   * It first tries to find an element with the matching ID.
   * If not found, it searches for an element with a `data-anchor-tag` attribute.
   * If either is found, it scrolls to the element using `scrollIntoView`.
   */
  const scrollToAnchor = async (hash) => {
    if (!isClient || !hash) return;

    await nextTick();
    try {
      let targetElement = document.querySelector(hash);

      if (!targetElement) {
        const anchorId = hash.slice(1);
        targetElement = document.querySelector(`[data-anchor-tag="${anchorId}"]`);
      }

      if (!targetElement) return;

      const headerHeight = hasStickyHeader ? getStickyHeaderHeight() : 0;
      const { top } = useElementBounding(targetElement, {
        updateTiming: 'next-frame',
      });
      window.scrollTo({
        top: top.value + window.scrollY - headerHeight,
        behavior: prefersReducedMotion.value === 'no-preference' ? 'smooth' : 'auto',
      });
    } catch (error) {
      console.warn('Failed to scroll to anchor:', error);
    }
  };

  return {
    validateAnchorRoute,
    scrollToAnchor,
  };
};
